var render = function () {
  var _vm$me4, _vm$me5, _vm$me6, _vm$me7, _vm$me8, _vm$me8$invites, _vm$me9, _vm$me9$organizers, _vm$me10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.meLoading ? _c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  }) : _vm._e(), _vm.me && !_vm.meLoading ? _c('b-nav-item-dropdown', {
    staticClass: "dropdown-user",
    attrs: {
      "right": "",
      "toggle-class": "d-flex align-items-center dropdown-user-link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        var _vm$me, _vm$me2, _vm$me3;
        return [_c('div', {
          staticClass: "d-sm-flex d-none user-nav"
        }, [_c('p', {
          staticClass: "user-name font-weight-bolder mb-0"
        }, [_vm._v(" " + _vm._s((_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : _vm$me.name) + " ")])]), _c('b-avatar', {
          staticClass: "badge-minimal",
          attrs: {
            "size": "40",
            "src": (_vm$me2 = _vm.me) === null || _vm$me2 === void 0 ? void 0 : _vm$me2.avatar_image,
            "variant": "light-primary"
          }
        }, [!((_vm$me3 = _vm.me) !== null && _vm$me3 !== void 0 && _vm$me3.name) ? _c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "22"
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 899433516)
  }, [(_vm$me4 = _vm.me) !== null && _vm$me4 !== void 0 && _vm$me4.id ? _c('b-dropdown-text', {
    attrs: {
      "link-class": "d-flex align-items-center"
    }
  }, [_c('b-row', {
    staticClass: "justify-content-between flex-wrap"
  }, [_c('b-col', {
    staticClass: "font-small-4 d-flex justify-content-start"
  }, [_vm._v(" Balance: ")]), _c('b-col', {
    staticClass: "font-small-4 d-flex justify-content-end",
    staticStyle: {
      "color": "#27AE60"
    }
  }, [_vm._v(" " + _vm._s(((_vm$me5 = _vm.me) === null || _vm$me5 === void 0 ? void 0 : _vm$me5.balance) || 0) + " € ")])], 1)], 1) : _vm._e(), _c('b-dropdown-divider'), (_vm$me6 = _vm.me) !== null && _vm$me6 !== void 0 && _vm$me6.id ? _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'player-profile',
        params: {
          id: (_vm$me7 = _vm.me) === null || _vm$me7 === void 0 ? void 0 : _vm$me7.id
        }
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "UserIcon"
    }
  }), _c('span', [_vm._v("Profile")])], 1) : _vm._e(), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'account-settings',
        params: {
          tab: 'settings'
        }
      },
      "link-class": "d-flex align-items-center"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "SettingsIcon"
    }
  }), _c('span', [_vm._v("Settings")])], 1), ((_vm$me8 = _vm.me) === null || _vm$me8 === void 0 ? void 0 : (_vm$me8$invites = _vm$me8.invites) === null || _vm$me8$invites === void 0 ? void 0 : _vm$me8$invites.length) > 0 ? _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'account-settings',
          params: {
            tab: 'invites'
          }
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "ZapIcon"
    }
  }), _c('span', [_vm._v("Manage invites")])], 1) : _vm._e(), _c('b-dropdown-divider'), _vm.$route.matched.some(function (_ref) {
    var path = _ref.path;
    return path.startsWith('/dashboard');
  }) ? _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.exitOrganizer
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "RepeatIcon"
    }
  }), _c('span', [_vm._v("Exit organizer")])], 1) : _vm._e(), _vm.allowOrganizerModeAccess() && !_vm.$route.matched.some(function (_ref2) {
    var path = _ref2.path;
    return path.startsWith('/dashboard');
  }) ? _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.enterOrganizerMode
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "RepeatIcon"
    }
  }), _c('span', [_vm._v("Organizer mode")]), ((_vm$me9 = _vm.me) === null || _vm$me9 === void 0 ? void 0 : (_vm$me9$organizers = _vm$me9.organizers) === null || _vm$me9$organizers === void 0 ? void 0 : _vm$me9$organizers.length) > 1 ? _c('select-organizer-modal', {
    attrs: {
      "organizer-list": (_vm$me10 = _vm.me) === null || _vm$me10 === void 0 ? void 0 : _vm$me10.organizers
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.allowOrganizerModeAccess() ? _c('b-dropdown-divider') : _vm._e(), _c('b-dropdown-item', {
    attrs: {
      "link-class": "d-flex align-items-center"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "size": "16",
      "icon": "LogOutIcon"
    }
  }), _c('span', [_vm._v("Logout")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }