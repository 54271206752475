<template>
  <b-modal
    id="select-organizer-modal"
    title="Choose organizer"
    no-close-on-backdrop
    size="sm"
    @hidden="handleHide"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-primary"
        @click="cancel"
      >
        Close
      </b-button>
    </template>

    <b-list-group>
      <b-list-group-item
        v-for="organizer in localOrganizerList"
        :key="organizer.id"
        class="d-flex align-items-center"
        @click="() => handleSelectClick(organizer.id)"
      >
        <b-avatar
          :src="organizer.avatar_image"
          :variant="`light-primary`"
          size="38px"
        />
        <h5
          style="display: inline"
          class="ml-1"
        >
          {{ organizer.name }}
        </h5>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import {
  BAvatar, BButton, BListGroup, BListGroupItem, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organizerList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localOrganizerList: this.organizerList || [],
    }
  },
  computed: {
  },
  watch: {
    organizerList(newOrganizerList) {
      this.localOrganizerList = newOrganizerList
    },
  },
  methods: {
    async handleSelectClick(organizerId) {
      await this.$router.push({
        name: 'dashboard',
        params: { id: organizerId },
      })
    },

    handleHide() {
    },
  },
}
</script>
