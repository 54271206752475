<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-lg-none">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>

    <app-breadcrumb />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <search-bar />

      <notification-dropdown v-if="me && !meLoading" />

      <user-dropdown />

      <authentication-links v-if="!me && !meLoading" />

    </b-navbar-nav>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

  </div>
</template>

<script>
import { BImg, BLink, BNavbarNav } from 'bootstrap-vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue'
import AuthenticationLinks from '@/layouts/components/AuthenticationLinks.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
    NotificationDropdown,
    AppBreadcrumb,
    AuthenticationLinks,
    UserDropdown,
    SearchBar,
    BLink,
    BNavbarNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    appLogoImage() {
      return $themeConfig.app.appLogoImage
    },
    appName() {
      return $themeConfig.app.appName
    },
    meLoading() {
      return this.$store.getters['user/isLoading']
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },

}
</script>

<style scoped>
.brand-logo img {
  height: 40px;
}

h2.brand-text {
  display: inline-block;
  color: #7367f0;
  padding-left: 1rem;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}
</style>
